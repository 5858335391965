import React from 'react'
import heroImage from 'assets/images/image1.jpeg'
import './hero.Module.scss'

export default function Hero() {
  return (
    <div className="hero">
      <div
        className="hero__image"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
      <div className="hero__container">
        <h1 className="hero__title">Uniunea Salvati Diaspora</h1>
        <p className="hero__text">
          Unim toți oamenii care au părăsit România și au încă un gând și doresc
          să contribuie la dezvoltarea acestei țări din care provin, promovând
          onestitatea, aptitudinile și valorile.
        </p>
      </div>
    </div>
  )
}
