import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React, { ChangeEventHandler, useState } from 'react'
import './contact.Module.scss'
import { Mail, Phone } from '@mui/icons-material'
import FormInput from 'components/forms/formInput/formInput'
import FormTextArea from 'components/forms/formTextArea/formTextArea'
import Button from 'components/ui/button/button'
import { toast } from 'react-toastify'
import api from 'utils/api'

export default function Contact() {
  const phone1 = '0040742314574'
  const phone2 = '004916092387165'
  const mail = 'uniuneasalvatidiaspora@gmail.com'

  interface formDataProps {
    name: string
    phone: string
    email: string
    message: string
  }

  const defaultFormDataVaule: formDataProps = {
    name: '',
    phone: '',
    email: '',
    message: '',
  }

  const [formData, setFormData] = useState<formDataProps>(defaultFormDataVaule)

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async () => {
    //eslint-disable-next-line
    const data: any = formData
    const valid = Object.keys(data).some((key) => data[key] != '')

    if (!valid) return toast.error('Toate campurile sunt obligatorii!')

    try {
      await api.post('/contact', formData)

      toast.success(
        'Mesaj trimis cu succes, o sa-ti raspundem cat mai repede posibil.',
      )
    } catch {
      toast.error('A aparut o eroare, incearca mai tarziu.')
    }

    setFormData(defaultFormDataVaule)
  }

  return (
    <div className="contact" id="contact">
      <SectionTitle
        title="Contact"
        subtitle="Contacteaza-ne pentru alte detalii"
      />
      <div className="contact__container">
        <div className="container__header">
          <div className="container__item">
            <div className="item__icon">
              <Phone fontSize="inherit" />
            </div>

            <div className="item__col">
              <label>{phone1}</label>
              <label>{phone2}</label>
            </div>
          </div>

          <a className="container__item" href={'mailto:' + mail}>
            <div className="item__icon">
              <Mail fontSize="inherit" />
            </div>

            <div className="item__col">
              <label>{mail}</label>
            </div>
          </a>
        </div>
        <div className="contact__form">
          <FormInput
            title="Nume"
            placeholder="Nume"
            name="name"
            value={formData.name}
            onChange={onChange}
          />
          <FormInput
            title="Telefon"
            placeholder="Telefon"
            name="phone"
            value={formData.phone}
            onChange={onChange}
          />
          <FormInput
            title="Email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={onChange}
          />
          <FormTextArea
            title="Mesaj"
            placeholder="Mesaj"
            name="message"
            value={formData.message}
            onChange={onChange}
          />
          <div className="contact__button">
            <Button onClick={onSubmit}>Trimite</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
