import NewsCard from 'components/newsCard/newsCard'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import useNews from 'hooks/useNews'
import React, { useEffect } from 'react'
import './news.Module.scss'

export default function News() {
  const { data, loading } = useNews()

  useEffect(() => {
    if (loading) return

    console.log(data)
  }, [loading])

  return (
    <>
      <div className="news">
        <SectionTitle
          title="Noutati"
          subtitle="Fii la curent cu toate noutatile"
        />

        <div className="news__container">
          {data.map((item) => {
            return <NewsCard key={item.slug as string} data={item} />
          })}
        </div>
      </div>
    </>
  )
}
