import React from 'react'
import About from 'components/about/about'
import AboutMembers from 'components/aboutMembers/aboutMembers'
import Administration from 'components/administration/administration'
import Hero from 'components/hero/hero'
import Purpose from 'components/purpose/purpose'
import Tasks from 'components/tasks/tasks'
import Order from 'components/order/order'
import Resolutions from 'components/resolutions/resolutions'
import GeneralMeeting from 'components/generalMeeting/generalMeeting'
import End from 'components/end/end'
import Contact from 'components/contact/contact'
import Sign from 'components/sign/sign'

export default function Home() {
  return (
    <>
      <Hero />
      <About />
      <Purpose />
      <AboutMembers />
      <Administration />
      <Tasks />
      <Order />
      <Resolutions />
      <GeneralMeeting />
      <End />
      <Contact />
      <Sign />
    </>
  )
}
