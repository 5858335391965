import { formatDateToDDMMYYYY } from 'func/formatDateToDDMMYYYY'
import React from 'react'
import { NewsProps } from 'types/news'
import './newsCard.Module.scss'

export default function NewsCard({ data }: { data: NewsProps }) {
  return (
    <div className="newsCard">
      <div
        style={{ backgroundImage: `url(${data.image})` }}
        className="newsCard__image"
      />
      <div className="newsCard__body">
        <label className="newsCard__date">
          {formatDateToDDMMYYYY(data.date)}
        </label>
        <label className="newsCard__title">{data.title}</label>
        <label className="newsCard__text">{data.description}</label>
      </div>
    </div>
  )
}
