import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import './end.Module.scss'

export default function End() {
  return (
    <div className="end">
      <SectionTitle
        title="Sfarsitul asociatiei"
        subtitle="Dizolvarea asociației, încetarea din alte motive, întreruperea scopurilor privilegiate din punct de vedere fiscal"
      />

      <div className="end__container">
        <p>
          În cazul dizolvării asociației, președintele consiliului de
          administrație și locțiitorul acestuia sunt lichidatori împuterniciți
          în comun, cu excepția cazului în care adunarea generală numește alte
          persoane.
        </p>

        <p>
          În cazul în care asociația este dizolvată sau desființată sau în cazul
          în care scopurile cu privilegii fiscale încetează să mai existe,
          bunurile asociației trec unei persoane juridice de drept public sau
          altei persoane cu privilegii fiscale în scopul de a contribui la
          soluționarea problemelor de romanii din strainatate.
        </p>

        <p>
          Prevederile de mai sus se aplică în mod corespunzător în cazul în care
          capacitatea juridică a asociației a fost retrasă.
        </p>
      </div>
    </div>
  )
}
