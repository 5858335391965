import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React, { ChangeEventHandler, FormEventHandler, useState } from 'react'
import FormInput from 'components/forms/formInput/formInput'
import './sign.Module.scss'
import Button from 'components/ui/button/button'
import { toast } from 'react-toastify'
import api from 'utils/api'

export default function Sign() {
  interface formDataProps {
    name: string
    email: string
    country: string
    county: string
    phone: string
  }

  const [formData, setFormData] = useState<formDataProps>({
    name: '',
    email: '',
    country: '',
    county: '',
    phone: '',
  })

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    //eslint-disable-next-line
    const data: any = formData

    const valid = Object.keys(data).some((a) => data[a] != '')

    if (!valid) return toast.error('Toate campurile sunt obligatorii!')

    try {
      await api.post('/joinus', formData)

      toast.success(
        'Inscriere inregistrata, o sa te contactam cat mai rapid posibil',
      )
    } catch {
      toast.error('A aparut o eroare, incearca mai tarziu.')
    }

    setFormData({ name: '', email: '', country: '', county: '', phone: '' })
  }

  return (
    <div className="sign" id="inscriere">
      <SectionTitle title="Alatura-te echipei" />
      <div className="sign__container">
        <form className="form" onSubmit={onSubmit}>
          <FormInput
            title="Nume"
            placeholder="Nume"
            name="name"
            onChange={onChange}
            value={formData.name}
          />
          <div className="form__row">
            <FormInput
              title="Email"
              placeholder="Email"
              name="email"
              onChange={onChange}
              value={formData.email}
            />
            <FormInput
              title="Telefon"
              placeholder="Telefon"
              name="phone"
              onChange={onChange}
              value={formData.phone}
            />
          </div>
          <div className="form__row">
            <FormInput
              title="Tara"
              placeholder="Tara"
              name="country"
              onChange={onChange}
              value={formData.country}
            />
            <FormInput
              title="Localitate"
              placeholder="Localitate"
              name="county"
              onChange={onChange}
              value={formData.county}
            />
          </div>
          <div className="form__cb">
            <input type="checkbox" required />
            <label>Accept prelucrarea datelor</label>
          </div>
          <div className="form__button">
            <Button>Trimite</Button>
          </div>
        </form>
      </div>
    </div>
  )
}
