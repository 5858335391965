import React from 'react'
import Header from './components/header/header'
import Footer from './components/footer/footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './layout.Module.scss'

interface layoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: layoutProps) {
  return (
    <div className="layout">
      <Header />
      <div className="layout__body">{children}</div>
      <Footer />
      <ToastContainer />
    </div>
  )
}
