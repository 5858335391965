import React from 'react'
import './sectionTitle.Module.scss'

interface sectionTitleProps {
  title: string
  subtitle?: string
}

export default function SectionTitle({ title, subtitle }: sectionTitleProps) {
  return (
    <div className="sectionTitle">
      <label className="title">{title}</label>
      {subtitle != null && <label className="subtitle">{subtitle}</label>}
    </div>
  )
}
