import { Menu } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './headerMobile.Module.scss'

export default function HeaderMobile() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const body = document.querySelector('body')
    const className = 'no-scroll'
    if (visible) body?.classList.add(className)
    else body?.classList.remove(className)
  }, [visible])

  const scrollTo = (id: string) => {
    setTimeout(() => {
      document.querySelector('#' + id)?.scrollIntoView({ behavior: 'smooth' })
    }, 125)
  }

  return (
    <>
      <div className="mobile__icon" onClick={() => setVisible(!visible)}>
        <Menu fontSize="inherit" />
      </div>

      <div className={`mobile__nav ${visible ? 'active' : ''}`}>
        <label
          onClick={() => {
            navigate('/')
            setVisible(false)
          }}
        >
          Noutati
        </label>

        <label
          onClick={() => {
            navigate('/about')
            setVisible(false)
          }}
        >
          Despre USD
        </label>

        <label
          onClick={() => {
            navigate('/about')
            setVisible(false)
            scrollTo('scopul')
          }}
        >
          Statutul USD
        </label>

        <label
          onClick={() => {
            navigate('/about')
            setVisible(false)
            scrollTo('inscriere')
          }}
        >
          Inscrie-te
        </label>

        <label
          onClick={() => {
            navigate('/about')
            setVisible(false)
            scrollTo('contact')
          }}
        >
          Contact
        </label>

        <label
          onClick={() => {
            window.open('https://votdiaspora.eu')
          }}
        >
          Vot Diaspora
        </label>

        <label
          onClick={() => {
            window.open(
              'https://www.facebook.com/profile.php?id=61554961525975&sfnsn=scwspwa',
            )
          }}
        >
          Facebook
        </label>
      </div>

      {visible && (
        <div
          className="mobile__overlay"
          onClick={() => {
            setVisible(false)
          }}
        />
      )}
    </>
  )
}
