import Layout from 'components/layout/layout'
import Home from 'pages/home/home'
import News from 'pages/news/news'
import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'

export default function App() {
  return (
    <HashRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<News />} />
          <Route path="/about" element={<Home />} />
        </Routes>
      </Layout>
    </HashRouter>
  )
}
