import contentful from 'utils/contentful'

export const getAll = async () => {
  const news = await contentful.getEntries({ content_type: 'usdNewsPost' })

  return news.items
    .sort((a, b) => {
      const dateA = new Date(a.fields.date as string)
      const dateB = new Date(b.fields.date as string)

      return dateB.getDate() - dateA.getDate()
    })
    .map((item) => {
      //eslint-disable-next-line
      const image: any = item.fields.image
      return {
        ...item.fields,
        image: 'https:' + image.fields?.file.url || null,
        date: new Date(item.fields.date as string),
      }
    })
}

export default {
  getAll,
}
