import React, { ButtonHTMLAttributes } from 'react'
import './button.Module.scss'

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

export default function Button({ children, ...otherProps }: buttonProps) {
  return (
    <button className="button" {...otherProps}>
      {children}
    </button>
  )
}
