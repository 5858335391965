import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import './tasks.Module.scss'

export default function Tasks() {
  return (
    <div className="tasks">
      <SectionTitle title="Sarcinile Comitetelor" />
      <div className="tasks__container">
        <p className="tasks__text">
          Consiliul de administrație al asociației este responsabil pentru
          reprezentarea asociației în conformitate cu secțiunea 26 din Codul
          civil german (BGB) și pentru gestionarea activității acesteia. În
          special, are următoarele atribuții:
        </p>
        <div className="tasks__list">
          <p>
            a) Convocarea și pregătirea adunărilor generale, inclusiv stabilirea
            ordinii de zi
          </p>

          <p>b) Implementarea hotărârilor adunării generale</p>

          <p>
            c) Gestionarea patrimoniului asociației și întocmirea raportului
            anual
          </p>

          <p>d) Acceptarea de noi membri</p>
        </div>
      </div>
    </div>
  )
}
