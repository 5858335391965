import React from 'react'
import './footer.Module.scss'

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <label className="footer__copyright">© All Rights Reserved</label>
      </div>
    </div>
  )
}
