import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import './resolutions.Module.scss'

export default function Resolutions() {
  return (
    <div className="resolutions">
      <SectionTitle title="Consilierea si rezolutia consiliului" />
      <div className="resolutions__container">
        <p>
          Consiliul se întrunește după cum este necesar. Ședințele sunt
          convocate de către președinte sau, în cazul în care acesta este în
          imposibilitate de a participa, de către adjunctul său. Trebuie
          respectată o perioadă de preaviz de o săptămână. Consiliul are cvorum
          dacă sunt prezenți cel puțin doi membri. Majoritatea voturilor valabil
          exprimate decide când se adoptă o rezoluție. În caz de egalitate,
          votul președintelui decide; în cazul în care acesta este în
          imposibilitate de a participa, votul decisiv are cel al
          vicepreședintelui său.
        </p>

        <p>
          Hotărârile consiliului de administrație trebuie consemnate.
          Procesul-verbal trebuie semnat de persoana care întocmește
          procesul-verbal și de către președinte sau, în caz de imposibilitate,
          de către adjunctul acestuia sau de un alt membru al consiliului de
          administrație.
        </p>
      </div>
    </div>
  )
}
