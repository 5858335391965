import React from 'react'
import './purpose.Module.scss'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'

export default function Purpose() {
  const listItems = [
    'Probleme ale românilor din străinătate',
    'O economie, legislație și impozitare funcționale',
    'Un sistem de justiție echitabil, independent și eficient',
    'O administrație clară, eficientă, corectă și incoruptibilă',
    'O dezvoltare eficientă, corectă, uniformă',
    'Un sistem electoral transparent, democratic',
    'Un sistem medical și educațional de calitate bazat pe meritocrație',
    'Creșterea calității vieții printr-o dezvoltare socio-economică durabilă',
    'Relații internaționale bazate pe democrație, valori și consolidarea unității românești',
    'Recunoașterea suveranității, integrității și independenței statului constituțional',
    'Sprijinirea intereselor și valorilor naționale în afara țării',
    'Respectarea convențiilor și tratatelor internaționale la care România este parte',
  ]

  return (
    <div className="purpose" id="scopul">
      <SectionTitle
        title="Scopul Nostru"
        subtitle="Detalii despre scopul asociatiei"
      />
      <div className="purpose__container">
        <label className="container__text">
          Asociația cu sediul în Ilmenau urmărește exclusiv și direct scopuri
          non profit în sensul secțiunii „Scopități privilegiate fiscal” din
          codul fiscal. Scopul asociației este promovarea statului democratic,
          promovarea prevenirii criminalității și promovarea cooperării pentru
          dezvoltare (potrivit § 52 alin. 2 din codul fiscal german). Scopul
          statutului este atins în special prin sprijinirea la:
        </label>
        <div className="container__list">
          {listItems.map((item, key) => {
            return (
              <label className="list__item" key={key}>
                • {item}
              </label>
            )
          })}
        </div>
        <label className="container__text">
          Asociația funcționează dezinteresat; nu urmărește în primul rând
          scopuri economice. Fondurile asociației pot fi utilizate numai în
          scopuri statutare. Membrii nu primesc nici o donație din fondurile
          asociației. Nimeni nu poate beneficia de cheltuieli care sunt în afara
          scopului asociației sau de remunerare disproporționat de mare.
        </label>
      </div>
    </div>
  )
}
