import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import './administration.Module.scss'

export default function Administration() {
  return (
    <div className="administration">
      <SectionTitle title="Consiliul de Admnistratie" />
      <div className="administration__container">
        <p>
          • Consiliul de administrație este format din președinte, locțiitorul
          acestuia și trezorier.
        </p>
        <p>
          • Președintele, adjunctul acestuia și trezorierul reprezintă fiecare
          asociație singur.
        </p>

        <p>
          • Membrii consiliului de administrație pot primi remunerație. Adunarea
          generală hotărăște cuantumul remunerației.
        </p>
      </div>
    </div>
  )
}
