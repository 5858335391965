import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import './generalMeeting.Module.scss'

export default function GeneralMeeting() {
  return (
    <div className="generalMeeting">
      <SectionTitle title="Adunarea Generala" />
      <div className="generalMeeting__container">
        <div className="container__item">
          <label className="item__title">Sarcinile adunarii generale</label>
          <div className="item__body">
            <p>
              Adunarea generală răspunde de hotărâri cu privire la următoarele
              aspecte: <br />
              a) modificări ale statutelor
              <br />
              b) stabilirea taxei de admitere și a cotizațiilor de membru
              <br />
              c) numirea membrilor de onoare și excluderea membrilor din
              asociație
              <br />
              d) alegerea și demiterea membrilor Membrii consiliului de
              administrație
              <br />
              e) primirea raportului anual și descărcarea de gestiune a
              consiliului de administrație
              <br />
              f) dizolvarea asociației.
            </p>
          </div>
        </div>

        <div className="container__item">
          <label className="item__title">Convocarea adunării generale</label>
          <div className="item__body">
            <p>
              Consiliul de administrație trebuie să convoace adunarea generală
              ordinară cel puțin o dată pe an, dacă este posibil în primul
              trimestru. Apelul se face în scris, cu un preaviz de două
              săptămâni și cu precizarea ordinii de zi.
            </p>
            <p>
              Consiliul stabilește ordinea de zi. Orice membru al clubului poate
              solicita o adăugare la ordinea de zi în scris de la consiliu cu
              cel puțin o săptămână înainte de adunarea generală. Consiliul
              decide asupra cererii. Adunarea generală hotărăște asupra
              propunerilor de ordine de zi care nu au fost acceptate de consiliu
              sau care sunt depuse pentru prima dată la adunarea generală cu
              majoritatea voturilor membrilor prezenți; Acest lucru nu se aplică
              cererilor care privesc modificări ale statutelor, modificări ale
              cotizațiilor sau dizolvarea asociației.
            </p>

            <p>
              Consiliul de administrație trebuie să convoace adunarea generală
              extraordinară dacă interesele asociației o impun sau dacă cel
              puțin o zecime dintre membri solicită acest lucru în scris,
              precizând scopul și motivele.
            </p>
          </div>
        </div>

        <div className="container__item">
          <label className="item__title">Hotărârea adunării generale</label>
          <div className="item__body">
            <p>
              Adunarea generală este condusă de președintele consiliului, în
              cazul în care acesta este în imposibilitate de a participa, de
              locțiitorul său și, în caz de imposibilitate de a participa, de un
              președinte de ședință care va fi ales de adunarea generală.
            </p>
            <p>
              Adunarea generală are cvorum dacă sunt prezenți cel puțin o treime
              din toți membrii clubului. Dacă nu este cvorum, consiliul de
              administrație este obligat să convoace o a doua adunare generală
              cu aceeași ordine de zi în termen de patru săptămâni. Acesta are
              cvorum indiferent de numărul membrilor prezenți. Acest lucru
              trebuie menționat în invitație.
            </p>

            <p>
              Adunarea generală hotărăște prin vot deschis cu majoritatea
              voturilor membrilor prezenți. Dacă nici un candidat nu obține
              majoritatea voturilor membrilor prezenți la alegeri, este aleasă
              persoana care obține majoritatea voturilor valabil exprimate. Un
              tur de scrutin trebuie să aibă loc între mai mulți candidați.
              Deciziile de modificare a statutului necesită o majoritate de trei
              sferturi, hotărârea de modificare a scopului sau dizolvarea
              asociației necesită aprobarea a nouă zecimi din membrii prezenți.
            </p>

            <p>
              Despre desfășurarea adunării generale și a hotărârilor adoptate
              trebuie întocmit un proces-verbal, care trebuie să fie semnat de
              persoana care întocmește procesul-verbal și de președintele
              adunării.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
