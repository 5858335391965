import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import aboutImage from 'assets/images/image4.jpeg'
import './about.Module.scss'

export default function About() {
  return (
    <div className="about">
      <SectionTitle
        title="Introducere"
        subtitle="Mai multe informatii despre noi"
      />

      <div className="about__container">
        <div className="about__body">
          <p className="about__text">
            Termenul diaspora (greaca veche pentru „dispersiune”) se referea
            inițial la un grup de oameni care au fost forțați să-și părăsească
            involuntar patria și au fost împrăștiați în mai multe țări străine
            sau în zona în care acest grup locuia atunci ca minoritate. Scopul
            USD este de a uni toți oamenii care au părăsit România și au încă un
            gând și doresc să contribuie la dezvoltarea acestei țări din care
            provin, promovând onestitatea, aptitudinile și valorile.
          </p>

          <p className="about__text">
            Asociația se numește Uniunea Salvați Diaspora (USD). Traducere în
            germană: Union Rettet die Diaspora. Va fi înscrisa în registrul
            asociațiilor și va avea apoi adaosul „e.V.” (eingetragenes Verein).
            Tradus din germana: asociatie inregistrata.
          </p>
        </div>
        <div
          className="about__image"
          style={{ backgroundImage: `url(${aboutImage})` }}
        ></div>
      </div>
    </div>
  )
}
