import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import './order.Module.scss'

export default function Order() {
  return (
    <div className="order">
      <SectionTitle title="Ordinele Consiliului Director" />
      <div className="order__container">
        <p>
          Membrii consiliului sunt aleși de adunarea generală pentru o perioadă
          de doi ani. Membrii consiliului de administrație pot fi doar membri ai
          asociației; Calitatea de membru în consiliu se încheie și cu calitatea
          de membru al asociației. Este permisă realegerea sau demiterea
          anticipată a unui membru de către adunarea generală. Un membru rămâne
          în funcție după încheierea mandatului obișnuit până când este ales
          succesorul său.
        </p>

        <p>
          Dacă un membru părăsește prematur consiliul, membrii rămași ai
          consiliului au dreptul să aleagă un membru al asociației în consiliu
          până când succesorul este ales de adunarea generală.
        </p>
      </div>
    </div>
  )
}
